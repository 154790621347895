/**
 * User reducer
 */
import {
  SET_USER_POLICY,
  STORE_USER,
  UPDATE_TERMS_AND_CONDITIONS,
  UPDATE_USER_PROFILE_OKTA,
  UPDATE_USER_PROFILE_OKTA_FAIL,
  UPDATE_USER_PASSWORD_OKTA,
  SET_USER_ROLE_CONFIG,
  SET_USER_ROLE_CONFIG_FAIL,
  SET_USER_APP_CONFIG,
  CLEAN_OKTA_MESSAGES_PASSWORD,
  SET_USER_PERMISSIONS,
  UPDATE_BROADCAST_MESSAGE_FAIL,
  UPDATE_BROADCAST_MESSAGE_SUCCESS,
  DISMISS_BROADCAST_MESSAGE_FAIL,
  DISMISS_BROADCAST_MESSAGE_SUCCESS,
  FETCH_BROADCAST_USERS_FAIL,
  FETCH_BROADCAST_USERS_START,
  FETCH_BROADCAST_USERS_SUCCESS,
  UPLOAD_NEW_DOCUMENT_START,
  UPLOAD_NEW_DOCUMENT_SUCCESS,
  UPLOAD_NEW_DOCUMENT_FAIL,
  UPDATE_DOCUMENT_FILE_START,
  UPDATE_DOCUMENT_FILE_SUCCESS,
  UPDATE_DOCUMENT_FILE_FAIL,
  GET_DOCUMENT_FILE_START,
  GET_DOCUMENT_FILE_SUCCESS,
  GET_DOCUMENT_FILE_FAIL,
  DELETE_DOCUMENT_FILE_START,
  DELETE_DOCUMENT_FILE_SUCCESS,
  DELETE_DOCUMENT_FILE_FAIL,
  GET_DOCUMENTS_BY_SITE_TYPE_START,
  GET_DOCUMENTS_BY_SITE_TYPE_SUCCESS,
  GET_DOCUMENTS_BY_SITE_TYPE_FAIL,
  GET_ALL_DOCUMENT_METADATA_START,
  GET_ALL_DOCUMENT_METADATA_SUCCESS,
  GET_ALL_DOCUMENT_METADATA_FAIL,
  FETCH_CATEGORY_NAMES_FAIL,
  FETCH_CATEGORY_NAMES_START,
  FETCH_CATEGORY_NAMES_SUCCESS,
  FETCH_FOLDER_NAMES_FAIL,
  FETCH_FOLDER_NAMES_START,
  FETCH_FOLDER_NAMES_SUCCESS,
  FETCH_SITE_TYPE_NAMES_FAIL,
  FETCH_SITE_TYPE_NAMES_START,
  FETCH_SITE_TYPE_NAMES_SUCCESS,
} from './types';

const initialState = {
  broadcastMessage: {},
  config: {},
  dismissingBroadcastMessage: false,
  dismissingBroadcastMessageError: null,
  permissions: {},
  policy: {},
  user: {},
  loadingBroadcastUsers: false,
  broadcastUsers: [],
  broadcastUsersError: null,
  isLoadingDocuments: false,
  documents: [],
  categoryNames: [],
  folderNames: [],
  siteTypeNames: [],
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPLOAD_NEW_DOCUMENT_START:
      return {
        ...state,
      };
    case UPLOAD_NEW_DOCUMENT_SUCCESS:
      return {
        ...state,
      };
    case UPLOAD_NEW_DOCUMENT_FAIL:
      return {
        ...state,
      };
    case UPDATE_DOCUMENT_FILE_START:
      return {
        ...state,
      };
    case UPDATE_DOCUMENT_FILE_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_DOCUMENT_FILE_FAIL:
      return {
        ...state,
      };
    case GET_DOCUMENT_FILE_START:
      return {
        ...state,
      };
    case GET_DOCUMENT_FILE_SUCCESS:
      return {
        ...state,
      };
    case GET_DOCUMENT_FILE_FAIL:
      return {
        ...state,
      };
    case DELETE_DOCUMENT_FILE_START:
      return {
        ...state,
      };
    case DELETE_DOCUMENT_FILE_SUCCESS:
      return {
        ...state,
      };
    case DELETE_DOCUMENT_FILE_FAIL:
      return {
        ...state,
      };
    case GET_DOCUMENTS_BY_SITE_TYPE_START:
      return {
        ...state,
      };
    case GET_DOCUMENTS_BY_SITE_TYPE_SUCCESS:
      return {
        ...state,
        documents: action.payload,
      };
    case GET_DOCUMENTS_BY_SITE_TYPE_FAIL:
      return {
        ...state,
      };
    case GET_ALL_DOCUMENT_METADATA_START:
      return {
        ...state,
        isLoadingDocuments: true,
      };
    case GET_ALL_DOCUMENT_METADATA_SUCCESS:
      return {
        ...state,
        isLoadingDocuments: false,
        documents: action.payload,
      };
    case GET_ALL_DOCUMENT_METADATA_FAIL:
      return {
        ...state,
        isLoadingDocuments: false,
      };
    case FETCH_BROADCAST_USERS_START:
      return {
        ...state,
        loadingBroadcastUsers: true,
        fetchBroadcastUsersError: null,
      };
    case FETCH_BROADCAST_USERS_SUCCESS:
      return {
        ...state,
        loadingBroadcastUsers: false,
        broadcastUsers: action.payload,
      };
    case FETCH_BROADCAST_USERS_FAIL:
      return {
        ...state,
        loadingBroadcastUsers: false,
        fetchBroadcastUsersError: action.payload,
      };
    case STORE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.user },
      };
    case SET_USER_POLICY:
      return {
        ...state,
        policy: action.payload,
      };
    case UPDATE_TERMS_AND_CONDITIONS:
      return {
        ...state,
        user: {
          ...state.user,
          termsAndCondition: action.payload,
        },
      };
    case UPDATE_USER_PROFILE_OKTA_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          oktaErrorMessage: action.payload,
        },
      };
    case UPDATE_USER_PASSWORD_OKTA:
      return {
        ...state,
        user: {
          ...state.user,
          oktaSuccessPassword: action.payload,
        },
      };
    case UPDATE_USER_PROFILE_OKTA:
      return {
        ...state,
        user: {
          ...state.user,
          mobilePhone: action.payload,
        },
      };
    case CLEAN_OKTA_MESSAGES_PASSWORD:
      return {
        ...state,
        user: {
          ...state.user,
          oktaErrorMessage: {},
          oktaSuccessPassword: false,
        },
      };
    case SET_USER_ROLE_CONFIG:
      return {
        ...state,
        user: {
          ...state.user,
          role: action.payload,
        },
      };
    case SET_USER_ROLE_CONFIG_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          role: action.payload,
        },
      };
    case SET_USER_APP_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    case SET_USER_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    case UPDATE_BROADCAST_MESSAGE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          role: action.payload,
        },
      };
    case UPDATE_BROADCAST_MESSAGE_FAIL:
      return {
        ...state,
        user: {
          ...state.user,
          role: action.payload,
        },
      };
    case DISMISS_BROADCAST_MESSAGE_FAIL:
      return {
        ...state,
        dismissingBroadcast: false,
        dismissBroadcastError: action.payload,
      };
    case DISMISS_BROADCAST_MESSAGE_SUCCESS:
      return {
        ...state,
        dismissingBroadcast: false,
        config: {},
      };
    case FETCH_CATEGORY_NAMES_START:
      return {
        ...state,
      };
    case FETCH_CATEGORY_NAMES_SUCCESS:
      return {
        ...state,
        categoryNames: action.payload,
      };
    case FETCH_CATEGORY_NAMES_FAIL:
      return {
        ...state,
      };
    case FETCH_FOLDER_NAMES_START:
      return {
        ...state,
      };
    case FETCH_FOLDER_NAMES_SUCCESS:
      return {
        ...state,
        folderNames: action.payload,
      };
    case FETCH_FOLDER_NAMES_FAIL:
      return {
        ...state,
      };
    case FETCH_SITE_TYPE_NAMES_START:
      return {
        ...state,
      };
    case FETCH_SITE_TYPE_NAMES_SUCCESS:
      return {
        ...state,
        siteTypeNames: action.payload,
      };
    case FETCH_SITE_TYPE_NAMES_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default userReducer;
